import React from "react";

interface TestimonialProps {
  avatar: string;
  name: String;
  position: String;
  company: String;
  children: React.ReactNode;
}

export default function TestimonialCard({
  avatar,
  name,
  position,
  company,
  children,
}: TestimonialProps) {
  return (
    <div className="flex flex-col md:flex-row items-center max-w-2xl lg:w-[42rem]  text-center md:text-left p-5 rounded-md shadow-md xl:first:-ml-14 xl:last:ml-14">
      <div className="w-24 h-24 md:mx-10 flex-shrink-0 rounded-full overflow-hidden">
        <img src={avatar} alt="" className="block w-full h-full object-cover" />
      </div>
      <div>
        <h3 className="mt-4 mb-0">{name}</h3>
        <div className="mb-4 text-sm">
          <span>{position}</span> @ <span>{company}</span>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
}
