import { Link } from "gatsby-plugin-react-i18next";
import React from "react";

export interface ButtonProps {
  text: String;
  link?: string;
  aClasses?: string;
  to?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  sitekey?: string;
}

const commonClasses =
  "block px-8 py-2 bg-gradient-to-r from-[rgba(74,81,252,1)] to-[rgba(107,255,176,1)] font-bold text-md text-white  text-center rounded-md  hover:no-underline focus:no-underline opacity-90 hover:opacity-100 focus-visible:opacity-100 transform hover:scale-105 focus-visible:scale-105 transition-all";

export default function ButtonGradient({
  text,
  link,
  aClasses,
  to,
  type,
  disabled,
  sitekey,
}: ButtonProps) {
  return to ? (
    <Link to={to} className={`${commonClasses} ${aClasses}`}>
      {text}
    </Link>
  ) : type ? (
    <button
      type={type}
      disabled={disabled}
      className={`${commonClasses} pointer-events-auto cursor-pointer disabled:from-gray-300 disabled:to-gray-300 disabled:pointer-events-none disabled:cursor-none  ${aClasses} `}
    >
      {text}
    </button>
  ) : (
    <a
      href={link}
      target="_blank  "
      className={`${commonClasses}  ${aClasses}`}
    >
      {text}
    </a>
  );
}
