import React from "react";
import ButtonGradient from "./ButtonGradient";

export interface ProductCardProps {
  title?: String;
  logo: string;
  children: React.ReactNode;
  btnText: String;
  btnLink: string;
}

export default function ProductCard({
  title,
  logo,
  children,
  btnText,
  btnLink,
}: ProductCardProps) {
  return (
    <div className="flex flex-col items-center justify-between shrink-0  max-w-[20rem]   text-center">
      <div className="flex flex-col items-center gap-2 mb-4">
        <img src={logo} alt="" className="block mx-auto h-32" />
        <h3 className="mb-0 mt-3">{title}</h3>
        <div className="mb-3">{children}</div>
      </div>
      <ButtonGradient text={btnText} link={btnLink} />
    </div>
  );
}
