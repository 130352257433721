import { graphql } from "gatsby";
import { Link } from "gatsby-plugin-react-i18next";
import { t } from "i18next";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import ButtonGradient from "../components/ButtonGradient";
import Layout from "../components/Layout";
import ProductCard from "../components/ProductCard";
import SEO from "../components/SEO";
import TestimonialCard from "../components/TestimonialCard";
import GrantBanner from "../templates/grant-bar";

//@ts-ignore
import Ai_learning_opt_image from "../images/Ai_learning_opt.png";
//@ts-ignore
import favicon_1_1167x1080_icon from "../images/favicon-1-1167x1080.png";
//@ts-ignore
import Logo_na_LI_NapisyDoWideo_PL_ from "../images/Logo-na-LI-NapisyDoWideo.PL_.png";
//@ts-ignore
import Callab_humansrobots_inCube_opt from "../images/Callab_humansrobots_inCube_opt.png";
//@ts-ignore
import logo_512 from "../images/logo-512.png";
//@ts-ignore
import remote_teams from "../images/remote.png";
//@ts-ignore
import Maciej_Michalewski from "../images/Maciej-Michalewski.jpeg";
//@ts-ignore
import KR from "../images/KR.jpeg";
//@ts-ignore
import MM from "../images/MM.jpeg";
//@ts-ignore
import contact_us_temp from "../images/contact_us_temp.png";

// markup
const IndexPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <SEO title={t("WE humans impact AI")} />
      <Layout>
        {/* 1st section */}
        <section className="flex flex-col-reverse lg:flex-row lg:items-center gap-5 lg:gap-9 mb-24 lg:mb-44 text-center lg:text-left">
          <div className="flex flex-col gap-3 lg:gap-6 items-center lg:items-start lg:basis-1/2 lg:p-12">
            <h2>
              <Trans>WE humans impact AI</Trans>
            </h2>
            <ul className="list-disc mb-3">
              <li>
                <Trans>WE launch good AI faster</Trans>
              </li>
              <li>
                <Trans>
                  WE gather and share info on how people perceive AI
                </Trans>
              </li>
              <li>
                <Trans>
                  WE promote an attitude of creating human-centered AI
                </Trans>
              </li>
            </ul>
            <ButtonGradient text={t("Are YOU with us")} to="/contact" />
          </div>
          <div className="lg:basis-1/2">
            <img
              className="block w-11/12 lg:w-full max-w-md lg:max-w-none mx-auto object-cover"
              src={Ai_learning_opt_image}
              alt=""
            />
          </div>
        </section>

        {/* 2nd section */}
        <section className="flex flex-col-reverse lg:flex-row-reverse lg:items-center gap-5 lg:gap-9 mb-24 lg:mb-44 text-center lg:text-left">
          <div className="flex flex-col gap-3 lg:gap-6 items-center lg:items-start lg:basis-1/2 lg:p-12">
            <h2>
              <Trans>Attitudes that WE value</Trans>
            </h2>
            <ul className="list-disc mb-3">
              <li>
                <Trans>Proactiveness</Trans>
              </li>
              <li>
                <Trans>Openness and Transparency</Trans>
              </li>
              <li>
                <Trans>Lifelong learning mindset</Trans>
              </li>
            </ul>
            <p className="max-w-lg">
              <Trans>
                Share what you think about AI to join the team which channels
                energy to positively impact the planet
              </Trans>
            </p>
            <p>
              <Trans>A spaceship we are all on-boarding</Trans>
            </p>
          </div>
          <div className="lg:basis-1/2">
            <img
              className="block w-11/12 lg:w-full max-w-md lg:max-w-none mx-auto object-cover"
              src={Callab_humansrobots_inCube_opt}
              alt=""
            />
          </div>
        </section>

        {/* our products */}
        <section className=" mb-24 lg:mb-44">
          {/* Voice solutions */}
          <div className="mb-28 ">
            <h2 className="mb-10 text-center">
              <Trans>Voice solutions</Trans>
            </h2>

            <div className="flex flex-col md:flex-row justify-center items-center md:items-stretch gap-20">
              {/* Stenograf */}
              <ProductCard
                title="Stenograf"
                logo={favicon_1_1167x1080_icon}
                btnText={t("View more")}
                btnLink="https://stenograf.io/">
                <p>
                  <Trans>SaaS captioning and transcriptions tool</Trans>
                </p>
                <p className="font-bold">
                  <Trans>
                    Have your subtitles ready in minutes at best value to price
                    ratio
                  </Trans>
                </p>
              </ProductCard>

              {/* NapisyDoWideo */}
              <ProductCard
                title="NapisyDoWideo"
                logo={Logo_na_LI_NapisyDoWideo_PL_}
                btnText={t("View more")}
                btnLink="https://napisydowideo.pl/">
                <p>
                  <Trans>End-to-end subtitling and transcribing services</Trans>
                </p>
                <p className="font-bold">
                  <Trans>We do captions right away</Trans>
                </p>
              </ProductCard>
            </div>
          </div>

          {/* labeling services */}
          <div className="">
            <h2 className="mb-10 text-center">
              <Trans>Labeling services</Trans>
            </h2>

            <div className="flex flex-col md:flex-row justify-center items-center md:items-stretch gap-20">
              {/* DataLabeling.EU */}
              <ProductCard
                title="DataLabeling.EU"
                logo={logo_512}
                btnText={t("Get labels")}
                btnLink="https://datalabeling.eu/">
                <p>
                  <Trans>
                    Voice visual text tabular and medical data annotations
                  </Trans>
                </p>
              </ProductCard>

              {/* Remote Teams */}
              <ProductCard
                title="Remote Teams"
                logo={remote_teams}
                btnText={t("Meet teams")}
                btnLink="https://datalabeling.eu/">
                <p>
                  <Trans>
                    Scalable operations within the EU optimized costs and no
                    more micromanagement
                  </Trans>
                </p>
              </ProductCard>
            </div>
          </div>
        </section>

        {/* testimonial */}
        <section className="flex flex-col items-center mb-24 lg:mb-44">
          <h2 className="text-center mb-10">
            <Trans>Testimonials</Trans>
          </h2>

          {/* testimonials */}
          <div className="flex flex-col items-stretch gap-6">
            <TestimonialCard
              avatar={Maciej_Michalewski}
              name="Maciej Michalewski"
              position="CEO &amp; Founder"
              company="Element">
              <Trans>
                DataLabeling.EU helped to train our machine learning model which
                allowed better results in the recruitment process
              </Trans>
            </TestimonialCard>
            <TestimonialCard
              avatar={KR}
              name="Krzysztof Rumianowski"
              position="Chairman"
              company="BPO Network">
              <Trans>
                WEimpact.Ai voice solutions help us in turning company
                conversations into the data assets for better decision making
              </Trans>
            </TestimonialCard>
            <TestimonialCard
              avatar={MM}
              name="Maciej Maliszewski"
              position="Head of Bots"
              company=" K2Bots.AI">
              <Trans>
                Testers team helps us to launch better AI driven chatbots
              </Trans>
            </TestimonialCard>
          </div>
        </section>

        {/* contact */}
        <section className="lg:flex lg:items-center lg:gap-16 xl:gap-28">
          <div className="flex flex-col items-center lg:items-start lg:basis-1/2 mb-16">
            <header className="">
              <h2 className="mb-5  text-center lg:text-left">
                <Trans>Contact</Trans>
              </h2>
              <p className="">
                <Trans>
                  We are a Polish company and we will gladly help your team to
                  scale AI projects. Enjoy a ☕ in our Warsaw office or connect
                  remotely to talk about the exciting artificial intelligence.
                </Trans>
              </p>
            </header>
            {/* address */}
            <div className="flex flex-col items-center lg:items-start gap-3 my-10">
              <div>
                <span className="block">
                  <Trans>Phone</Trans>: +48 571-481-980
                </span>
              </div>

              <ButtonGradient
                text={t("Contact us")}
                to="/contact"
                aClasses="mb-2"
              />
            </div>
          </div>

          <div className="lg:basis-1/2">
            <img src={contact_us_temp} alt="" />
          </div>
        </section>

        <div className="text-center lg:text-left bottom-0 left-0">
          <Link
            to="/policy"
            className="text-accent opacity-80 hover:opacity-100">
            <Trans>Privacy policy</Trans>
          </Link>{" "}
          |{" "}
          <Link
            to="/terms"
            className="text-accent opacity-80 hover:opacity-100">
            <Trans>Terms and conditions</Trans>
          </Link>
        </div>

        <footer className="w-full my-10 text-s lg:flex lg:items-stretch">
          <div className="lg:w-1/4 mb-10">
            <div>
              <span className="block text-2xl">
                WEimpact Sp. z o.o.
              </span>
              <span className="block">NIP: PL 7010951924</span>
            </div>

            <br />
            <div>
              <span className="block">
                <Trans>3/G Jana Janowicza Street</Trans>
              </span>
              <span className="block">
                <Trans>10-692 Olsztyn Poland EU</Trans>
              </span>
            </div>
          </div>

          <div className="lg:w-3/4">
            <p>
              <Trans>The R&D project called "Stenograf" is co-financed by the European Union as part of the Operational Program Intelligent Development 2014-2020 Priority 1 Support for R&D works by enterprises, Measure 1.3: R&D works financed with the participation of capital funds, Sub-measure 1.3.1: Support for research and development projects in the preseed phase by proof-of-concept funds - BRIdge Alpha - implemented by Tech-Impact Fund Sp. z o.o.</Trans>
            </p>

            <div className="flex items-start mt-5 h-20">
              <p className="max-w-3/4 mt-auto">
                <span>
                  <Trans>Value of the project: PLN 1,120,000</Trans>
                  <br /><Trans>European Funds contribution: PLN 896,000</Trans>
                </span>
              </p>

              <img
                className="max-w-1/4 max-h-20 ml-5 mt-auto"
                src="https://flagcdn.com/eu.svg"
                alt="European Union"
              />
            </div>
          </div>
        </footer>
      </Layout>

      <GrantBanner />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
