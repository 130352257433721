import React, { useState, useEffect } from 'react';

//@ts-ignore
import grant_info from "../images/grant-info.jpeg";

export default function GrantBanner() {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
      const onScroll = () => setOffset(window.pageYOffset);

      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
      offset <= 300
      ? <div className="fixed bottom-0 w-full mb-1">
        <img className="max-w-fit w-2/5 mx-auto cursor-pointer" src={grant_info} onClick={() => window.scrollTo({top: document.body.scrollHeight, behavior: "smooth"})}/>
      </div>
      : null
  );
}